import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import CareersDetailsView from '../../components/CareersDetails';

const CareersDetails = ({ location, data }) => (
  <CareersDetailsView
    location={location}
    title="React Native Mobile Engineer - Software Careers | MCRO"
    description={`We are seeking a talented Mobile Developer who shares our goal create the best products in the market.
     You are the type of person who loves making products that your customer loves. You take pride in quality code 
     which is resilient, reliable, and maintainable. We work with dozens of APIs and frameworks and are truly a 
     platform on which our customers utilize as their primary and central tool.`}
    metaContent="react-native, ios, android, javascript, swift, redux, es7"
    id="react-native"
    jobTitle="React Native - Mobile Engineer"
    jobDescription={`We are seeking a talented Mobile Developer who shares our goal create the best products
     in the market. You are the type of person who loves making products that your customer loves. You take
      pride in quality code which is resilient, reliable, and maintainable. We work with dozens of APIs and
     frameworks and are truly a platform on which our customers utilize as their primary and central tool.`}
    mailHref="mailto:jobs@mcro.tech?subject=React-Native Job Application"
    responsibilities={[
      'Implement complex and core functionality within our mobile app related to scheduling, payments and billing,'
      + ' route, internal/external APIs, etc',
      'Own our mobile department and find ways to improve existing systems and processes',
      'Help mentor and coach junior members of our development team',
      'Work with the latest technologies and tools to solve the oldest problems which affect our customers'
    ]}
    techSkills={[
      'Bachelor’s degree in Computer Science or related field',
      'At least 3 years expert level React Native experience',
      'At least 3 years expert level experience with mobile apps that have offline capabilities',
      'At least 1 year experience with iOS native development. We use native code for a few features integrated with'
      + ' React Native app',
      'At least 1 year with Android native development. We use native code for few features integrated with React'
      + ' Native app',
      'At least 3 years experience implementing complex and core features into a mature code base',
      'Strong knowledge of mobile UI design principles, patterns, and best practices',
      'Familiarity with RESTful APIs to connect to back-end service',
      'Proven ability to track down, solve, and release solutions to bugs and issues',
      'Experience with version control and deployment strategies',
      'High attention to detail and ability to take responsibility for core changes',
      'Passion for development and high desire to release code',
      'Experience with Realm or SQLite databases']}
    offerings={[
      'Competitive compensation',
      'Possibility to work remote when desired',
      'Work with the best professionals in the area',
      'Great work environment in our Cluj Napoca-Office',
      'Private healthcare insurance for employees'
    ]}
    imageElement={data}
  />
);

CareersDetails.propTypes = {
  location: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({})
};

CareersDetails.defaultProps = {
  data: null
};

export default CareersDetails;

export const query = graphql`
  query reactNativeCareerPage {
    file(relativePath: { eq: "careers-details-banner.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1440)  {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
